<template>
  <v-app>
    <v-main class="mainContentBg">
      <v-container py-0  fill-height>
        <v-row align="center" justify="center">
          <v-col sm="10" offset="1">
            <div class="box">
              <div class="content">
                <div class="header">
                  <img src="/logo.svg" v-if="step === 0" class="logo">
                  <h6 class="main-title" v-if="step !== 0">ASISTENTE DE INICIO TICKSY</h6>
                  <Welcome :step="step" v-if="step === 0"/>
                  <Wizard v-else :currentStep="step" @change="setStep"/>
                </div>
              </div>

              <div class="footer" v-if="step === 0">
                  <v-btn color="primary" @click="step++">¡COMENZAR!</v-btn>
              </div>

            </div>
          </v-col>
        </v-row>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import {theme_dark, theme_light} from '@/scripts/themes';
import Welcome from "@/components/Welcome";
import Wizard from "@/components/Wizard";

export default {
  name: 'App',
  data: () => ({
    step: 0
  }),
  components: {
    Welcome,
    Wizard
  },
  methods:{
    setStep(step){
      this.step = step
    }
  },
  mounted() {
    this.$vuetify.theme.themes.dark = theme_dark
    this.$vuetify.theme.themes.light = theme_light
    console.log(process.env.VUE_APP_BASE_API)
  }
};
</script>

<style lang="scss">
.box {
  .welcome {
    height: 310px;
  }
  .main-title {
    font-size: 0.90rem;
    font-weight: normal;
    color: white;
    padding: 5px 0;
  }

  .logo {
    padding: 40px 0;
  }

  margin: 0 auto;
  padding: 0;
  width: 80%;
  min-height: 600px;
  text-align: center;
  color: #16334E;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .header {
    padding: 5px 0 0 0;
    background-color: #1ED8F6;
    border-radius: 10px;

  }

  .content {
    min-height: 465px;
  }

  .footer {
    background-color: white;
    border-radius: 10px;
    padding: 50px 0;
    height: 150px;
  }
}

</style>
