
let instance;

const Plugin = {
  install: function (Vue) {
    // Makes sure that plugin can be installed only once
    if (this.installed) {
      return;
    }
    this.installed = true;

    instance = new Validations();

    // 1. add global method or property
    Vue.validations = instance;

    // 4. add an instance method
    Vue.prototype.$validations = instance;
  }
};

// Utils para validar diversos tipos de valores.
// Cada metodo valida un tipo de valor en caso de llegar (no vacio)
// Esto es asi para que se pueda validar por ejemplo un campo de email no obligatorio
// Si se quiere que ademas de ser un email sea obligatorio se pueden juntar dos rules: required e isEmail
class Validations {
  constructor() {

  }

  // Comprueba si es un email valido. Opcionalmente se pueden comprobar un listado de emails separados por comas
  isEmail(msg, multiple = false) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (multiple) {
      re = /^((([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})),?\s?)+$/;
    }
    return (v) => {
      if (v !== null || (v !== '' && v !== ' ')) {
        return true;
      }
      return (re.test(String(v).toLowerCase())) ? true : msg;
    }
  }
}

export default Plugin;




