import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import '@/sass/styles.scss';
import axios from 'axios'
import VueAxios from 'vue-axios'
import ValidationRules from "@/scripts/ValidationRules";

Vue.config.productionTip = false

axios.defaults.baseURL = process.env.VUE_APP_BASE_API

Vue.use(VueAxios, axios)
// Plugin Validaciones
Vue.use(ValidationRules);

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
