<template>
  <v-container v-if="step===0" class="welcome">
    <v-row>
      <v-col cols="8" offset="2">
        <img src="/happy.svg" alt="" width="48">
        <p class="mt-8">
          Bienvenido al <strong>ASISTENTE DE INICIO DE TICKSY</strong>.<br/>
          En uno minutos, tendrás tu entorno listo para comenzar a recibir pedidos.
        </p>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Welcome',
  props: {
    step: {
      type: Number,
      default: 0
    }
  }
}
</script>
