<template>

  <v-stepper v-model="currentStep" alt-labels class="wizard">
    <v-stepper-header class="mt-6 mb-6">
      <template v-for="step in steps">
        <v-stepper-step :complete="currentStep > step.index" :step="step.index">
          {{ step.name }}
        </v-stepper-step>
        <v-divider v-if="step.index<steps.length"/>
      </template>

    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content v-for="step in steps" :step="step.index" :key="step.index">
          <component v-model="formParams" :is="step.component" @back="goBack" @next="goNext" />
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>

</template>

<script>
import wizardVO from "@/VO/WizardVO"

export default {
  name: "Wizard",
  data: () => ({
    currentStep: 1,
    formParams: wizardVO,
    steps: [
      {index: 1, name: "Acceso", component: () => import("@/components/Steps/User")},
      {index: 2, name: "Creación de tipo de local",  component: () => import("@/components/Steps/LocalType")},
      {index: 3, name: "Creación de local", component: () => import("@/components/Steps/Local")},
      {index: 4, name: "Verificacion dirección del local", component: () => import("@/components/Steps/SearchLocalAddress")},
      {index: 5, name: "¡Listo!", component: () => import("@/components/Steps/Ready")},
    ]
  }),
  methods: {
    async goNext(){
      await this.$nextTick()
      if(this.currentStep===4) {
        console.log(this.formParams)
        await this.$http.post('/v2/wizard/create_local', this.formParams)
      }
      this.currentStep++
    },
    goBack(){
      this.currentStep--
      this.$emit("change", this.currentStep)
    }
  }
}
</script>
