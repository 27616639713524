export default {
    user: {
        displayName: "",
        email: "",
        password: ""
    },
    business: {
        name: "",
        enterprise: "",
        vat_id: "",
        type: "",
        address: "",
        city: "",
        state: "",
        postal_code: "",
        phone: "",
        currency: "euro",
        email: "",
        dealer_id: "",
        checkouts_email: ""
    },
    worker: {
        name: "",
        short_name: "",
        pcode: "",
        access: {
            access_stats: false,
            edit_tickets: false,
            modify_invoices: false,
            modify_prices: false,
            remove_items: false
        }
    }
}