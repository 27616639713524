export const c_white = '#ffffff'; // Blanco del fondo del contenido (Light)
export const c_shark = '#22282a'; // Gris oscuro del del fondo del contenido (Dark)
export const c_turquoise = '#1ED8F6'; // Azul turquesa destacado (Light y Dark)
export const c_falu_red = '#901313'; // Rojo de los textfield con error (Dark)
export const c_oxford_blue = '#28303a'; // Gris verdoso del fondo del sidebar (Light)
export const c_cinder = '#1d2324'; // Gris verdoso del fondo del sidebar (Dark)
export const c_san_marino = '#4c7897'; // Gris intermedio del texto del sidebar (Light)
export const c_traut = '#474e53'; // Gris intermedio del texto del sidebar (Dark)
export const c_solitude = '#f7f9fb'; // Gris claro del fondo de la barra de titulo (Light)
export const c_cinder_2 = '#1f2527'; // Gris oscuro del fondo de la barra de titulo (Dark)
export const c_blue_bayoux = '#5c6a78'; // Gris azulado del texto de todos los fields (Light)
export const c_silver = '#b7b7b7'; // Gris claro del texto de todos los fields (Dark)
export const c_gainsboro = '#d8d8d8'; // Gris claro del borde del la barra de titulo (Light)
export const c_charade = '#404547'; // Gris claro del borde del la barra de titulo (Dark)
export const c_fire_brick = '#ae1e1e'; // Rojo de los textfield con error (Light)

// Backup del tema original para que no se pisen los colores
let bkp = {
    'primary': '#1976D2',
    'secondary': '#424242',
    'accent': '#82B1FF',
    'error': '#FF5252',
    'info': '#2196F3',
    'success': '#4CAF50',
    'warning': '#FB8C00'
};

export const theme_light = {
    ...bkp,
    ...{
        primary: c_turquoise,
        secondary: c_fire_brick,
        accent: c_turquoise,
        error: c_fire_brick,
        sidebar: c_oxford_blue,
        sidebarText: c_san_marino,
        toolbar: c_solitude,
        toolbarText: c_blue_bayoux,
        toolbarBorder: c_gainsboro,
        mainContentBg: c_white,
    }
};

export const theme_dark = {
    ...bkp,
    ...{
        primary: c_turquoise,
        secondary: c_falu_red,
        accent: c_turquoise,
        error: c_falu_red,
        sidebar: c_cinder,
        sidebarText: c_traut,
        toolbar: c_cinder_2,
        toolbarText: c_silver,
        toolbarBorder: c_charade,
        mainContentBg: c_shark,
    }
}
